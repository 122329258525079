














































































import { Vue, Component } from "vue-property-decorator";
import { AdminRouter } from "@/utils/routePathContsant";
import pagination from "@/components/UIComponents/Pagination.vue";
import { SpeedSection, SpeedSectionSearch } from "@/store/models/meta";
import metaStore from "@/store/modules/meta";

@Component({
  components: {
    pagination,
  },
})
export default class SectionListView extends Vue {

  qUsers: SpeedSection[] = [];

  async created() {
    await metaStore.getSpeedSection();
  }

  get speedSectionList(){
    return metaStore.speedSectionList;
  }

  get AdminRouter() {
    return AdminRouter;
  }
}
